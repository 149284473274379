














































































import {
  Watch,
  Prop,
  Component,
  Vue
} from 'vue-property-decorator';
import User from '@/modules/User';
import LeaseItemList, { ILeaseItemList_Item } from '@/modules/LeaseItem/List';

@Component
export default class Items extends Vue {
  canEditLeases     = false;
  canAddLeaseItems  = false;
  canEditLeaseItems = false;
  canSeeLeasePrices = false;
  isBusy            = true;
  leaseItemList     = new LeaseItemList();
  fields            = [] as Array < any > ;

  navigateToEditLease() {
    this.$router.push(`/Lease/${this.leaseItemList.LeaseId}/Edit`);
  }

  navigateToFiles() {
    this.$router.push(`/Lease/${this.leaseItemList.LeaseId}/Files`);
  }

  navigateToAddRentalObject() {
    this.$router.push(`/Lease/${this.leaseItemList.LeaseId}/AddRentalObject`);
  }

  navigateToAddStockItem() {
    this.$router.push(`/Lease/${this.leaseItemList.LeaseId}/AddStockItem`);
  }

  navigateToAddTextItem() {
    this.$router.push(`/Lease/${this.leaseItemList.LeaseId}/AddTextItem`);
  }

  itemSelected(record: ILeaseItemList_Item, idx: number) {
    if (!this.canEditLeaseItems)
      return;

    switch (record.Type) {
      case 'H':
        return this.$router.push(`/Lease/${this.$route.params.leaseId}/EditRentalObject/${record.Id}`);
      case 'S':
        return this.$router.push(`/Lease/${this.$route.params.leaseId}/EditStockItem/${record.Id}`);
      case 'T':
        return this.$router.push(`/Lease/${this.$route.params.leaseId}/EditTextItem/${record.Id}`);
    }
  }

  getDates(data: any) {
    const matches = (data.value + '').match(/[0-9]{4}\-[0-9]{2}\-[0-9]{2}/g);

    if (!matches)
      return [];
      
    return matches.map((match: string, index: number) => ({ value: match, isLast: index === (matches.length - 1)}));
  }

  async onDelete(item: ILeaseItemList_Item) {
    const isConfirmed = await (this as any).$bvModal.msgBoxConfirm(this.$t('Are you sure?') !, {
      title: `${this.$t('Delete')} ${item.Name}`,
      okVariant: 'danger',
      okTitle: this.$t('Yes'),
      cancelTitle: this.$t('Cancel'),
      hideHeaderClose: false,
      centered: true
    }).catch(() => {});

    if (isConfirmed) {
      await LeaseItemList.Delete(item.Id);
      this.fetchLeaseItems();
    }
  }

  async fetchLeaseItems() {
    this.isBusy = true;
    this.leaseItemList = await LeaseItemList.Find(+this.$route.params.leaseId);
    this.isBusy = false;
  }

  async constructTableFields() {
    const fields = [
      {key: 'Type',          label: this.$t('Type'),          class: 'display-priority-5'},
      {key: 'Number',        label: this.$t('Number')                                    },
      {key: 'InventoryItem', label: this.$t('Inventory item')                            },
      {key: 'Name',          label: this.$t('Name'),                                     },
      {key: 'Quantity',      label: this.$t('Quantity'),      class: 'display-priority-1'},
      {key: 'Unit',          label: this.$t('Unit'),          class: 'display-priority-5'},
      {key: 'Date',          label: this.$t('Date'),          class: 'display-priority-3'},
      {key: 'RentalPeriod',  label: this.$t('Rental period'), class: 'display-priority-2'},
    ];

    if (this.canSeeLeasePrices) {
      fields.push(
        {key: 'UnitPrice', label: this.$t('Unit price'),      class: 'display-priority-4'},
        {key: 'Discount',  label: this.$t('Discount'),        class: 'display-priority-4'},
        {key: 'NetAmount', label: this.$t('Net amount'),      class: 'display-priority-5'},
      );
    }

    if (this.canEditLeaseItems) {
      fields.push({ key: 'Actions', label: '', tdClass: 'text-center', thStyle: 'width: 80px;' } as any);
    }
    
    this.fields = fields;
  }

  async mounted() {
    this.canEditLeases     = await User.Can('EditLeases');
    this.canAddLeaseItems  = await User.Can('AddLeaseItems');
    this.canEditLeaseItems = await User.Can('EditLeaseItems');
    this.canSeeLeasePrices = await User.Can('SeeLeasePrices');

    await this.$nextTick();
    await this.constructTableFields();
    await this.fetchLeaseItems();
  }
}
